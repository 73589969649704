import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";

const getProducts = async () => {
  const response = await axios.get(`${base_url}product/`);

  return response.data;
};

const getAProduct = async (id) => {
  const response = await axios.get(`${base_url}product/${id}`);

  return response.data;
}

const createProduct = async (product) => {
  const response = await axios.post(`${base_url}product/`, product, config);

  return response.data;
};

const deleteProduct = async (id) => {
  const response = await axios.delete(`${base_url}product/${id}`, config);

  return response.data;
};

const updateProduct = async (product) => {
  try {
    const { _id, ...dataToSend } = product;
    const response = await axios.put(
      `${base_url}product/${product._id}`,
      dataToSend,
      config
    );
    console.log("id", product._id)
    console.log("test ligne 68")
    console.log("response", response) 
    return response.data;
  } catch (error) {
    console.error("Une erreur s'est produite lors de la mise à jour du produit :", error);
    throw error;
  }
}

const setPromo = async (productData) => {
  const product = await getAProduct(productData.productId);

  const id = productData.productId;
  const promoPrice = productData.promoPrice;
  const basePrice = product.price;
  console.log(basePrice, promoPrice)
  

  try {
    const response = await axios.put(`${base_url}product/promo/${id}`, { isPromo: true, promoPrice: promoPrice, basePrice: basePrice}, config);
    await axios.put(`${base_url}product/${id}`, { price: promoPrice }, config);
    console.log(product)
    return response.data;
  } catch (error) {
    console.error("Une erreur s'est produite lors de la mise à jour du produit :", error);
    throw error;
  }
  
}

const removePromo = async (id) => {
  const product = await getAProduct(id);
  const price = product.basePrice;

  try {
    const response = await axios.put(`${base_url}product/removepromo/${id}`, {isPromo: false}, config);
    await axios.put(`${base_url}product/${id}`, { price: price }, config);
    return response.data;
  }
  catch (error) {
    console.error("Une erreur s'est produite lors de la mise à jour du produit :", error);
    throw error;
  }
}


const productService = {
  getProducts,
  createProduct,
  deleteProduct, 
  updateProduct, 
  getAProduct,
  setPromo,
  removePromo
};

export default productService;

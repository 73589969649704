import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit, BiDetail } from "react-icons/bi";
import { RiDeleteBack2Line } from "react-icons/ri";
import { AiFillDelete, AiOutlineUserDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getProducts, deleteProduct } from "../features/product/productSlice";
import { Link } from "react-router-dom";
import CustomModal from "../components/CustomModal";
import { removePromo } from "../features/product/productSlice";

const columns = [
  
  {
    title: "Produit",
    dataIndex: "title",
    sorter: (a, b) => a.title.length - b.title.length,
  },
  {
    title: "Marque",
    dataIndex: "brand",
    sorter: (a, b) => a.brand.length - b.brand.length,
  },
  {
    title: "Categorie",
    dataIndex: "category",
    sorter: (a, b) => a.category.length - b.category.length,
  },
  {
    title : "Quantité",
    dataIndex: "quantity",
  },
  {
    title: "Prix",
    dataIndex: "price",
    sorter: (a, b) => a.price - b.price,
  },
  {
    title: "Action",
    dataIndex: "action",
  },
];


const Productlist = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProducts());
  }, []);

  const hideModal = () => {
    setOpen(false);
  };


  const [openPromo, setOpenPromo] = useState(false);
  const [promoID, setPromoID] = useState(null);


 

  const [open, setOpen] = useState(false);
  const [productID, setProductID] = useState("");
  const productState = useSelector((state) => state.product.products);
  const data1 = [];

  const showModal = (e) => {
    setOpen(true);
    setProductID(e);
  };
  for (let i = 0; i < productState.length; i++) {
    data1.push({
      key: productState[i]._id,
      title: productState[i].title,
      brand: productState[i].brand,
      category: productState[i].category,
      price: `${productState[i].price}`,
      quantity: `${productState[i].quantity}`,
      action: (
        <>
        
          <Link to={`/admin/product/${productState[i]._id}`} className=" fs-3 text-danger">
            <BiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(productState[i]._id)}
          >
            <AiFillDelete />
          </button>

          <Link to={`add-promo/${productState[i]._id}`} className="ms-3 fs-3 text-primary">
            <BiDetail />
          </Link>

          <button onClick={() => {setOpenPromo(true); setPromoID(productState[i]._id);}} className="btn ms-3 mb-3 fs-3 text-primary">
            <RiDeleteBack2Line />
          </button>

        </>
      ),
    });
  }
  console.log(data1);

  const d = (e) => {
    dispatch(deleteProduct(e));
    setOpen(false);
    setTimeout(() => {
      dispatch(getProducts());
    }, 100);
  };

  const dPromo = (e) => {
    dispatch(removePromo(e));
    setOpenPromo(false);
    setTimeout(() => {
      dispatch(getProducts());
    }, 100);
  };
  

  return (
    <div>
      <h3 className="mb-4 title">Produit</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          d(productID);
        }}
        title="Etes-vous sûr de vouloir supprimer ce produit? "
      />
      <CustomModal
      hideModal={() => setOpenPromo(false)}
      open={openPromo}
      performAction={() => {
        dPromo(promoID);
      }}
      title="Etes-vous sûr de vouloir supprimer cette promotion? "
    />
    </div>
  );
};



export default Productlist;

import React, { useEffect } from "react";
import { BsArrowDownRight } from "react-icons/bs";
import { Column } from "@ant-design/charts";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { monthlyRevenue, yearlyRevenue } from "../features/auth/authSlice";
import { getOrders } from "../features/auth/authSlice";


const Dashboard = () => {
  const yearly = useSelector((state) => state.auth.yearlyRevenue);
  const monthly = useSelector((state) => state.auth.monthlyRevenue);

  const orderState = useSelector((state) => state.auth.orders);

  
  console.log("orderState", orderState);

 
  
  console.log("yearly", yearly);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
    dispatch(monthlyRevenue());
    dispatch(yearlyRevenue());
  }, [dispatch]);
  

  const yearValue = Object.values(yearly);

  const currentYear = yearValue[1];
  const previousYear = yearValue[0];

  // Calculate percentage change between current and previous year
  const percentageChange =
    ((currentYear - previousYear) /
    previousYear) *
    100;

  const data = [
    { type: "Jan", sales: monthly[0]?.totalRevenue || 0 },
    { type: "Fév", sales: monthly[1]?.totalRevenue || 0 },
    { type: "Mar", sales: monthly[2]?.totalRevenue || 0 },
    { type: "Avr", sales: monthly[3]?.totalRevenue || 0 },
    { type: "Mai", sales: monthly[4]?.totalRevenue || 0 },
    { type: "Jun", sales: monthly[5]?.totalRevenue || 0 },
    { type: "Jul", sales: monthly[6]?.totalRevenue || 0 },
    { type: "Aoû", sales: monthly[7]?.totalRevenue || 0 },
    { type: "Sep", sales: monthly[8]?.totalRevenue || 0 },
    { type: "Oct", sales: monthly[9]?.totalRevenue || 0 },
    { type: "Nov", sales: monthly[10]?.totalRevenue || 0 },
    { type: "Déc", sales: monthly[11]?.totalRevenue || 0 },
  ];

  const columns = [
    { title: "SNo", dataIndex: "key" },
    { title: "Nom", dataIndex: "name" },
    {
      title: "Montant",
      dataIndex: "amount",
    },
    {
      title: "Date",
      dataIndex: "date",
    }
  ];



  
  const sortedOrders = [...orderState].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const data1 = sortedOrders.slice(0, 10).map((order) => ({
    key: order._id,
    name: order.orderby?.firstname || 'Unknown',
    amount: order.paymentIntent?.amount || 0,
    date: new Date(order.createdAt).toLocaleString(),
  }));
  

  const config = {
    data,
    xField: "type",
    yField: "sales",
    color: "rgb(245, 114, 203)",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: { alias: "Mois" },
      sales: { alias: "Revenu" },
    },
  };

  return (
    <div>
      <h3 className="mb-4 title">Tableau de bord</h3>
      <div className="d-flex justify-content-between align-items-center gap-3">
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white p-3 rounded-3">
          <div>
            <p className="desc">Total</p>
            <h4 className="mb-0 sub-title">{currentYear || 0} FCFA</h4>
          </div>
          <div className="d-flex flex-column align-items-end">
            <h6>
              <BsArrowDownRight /> {percentageChange}%
            </h6>
            <p className="mb-0 desc">Comparé à l'année dernière</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <h3 className="mb-5 title">Statistiques des revenus</h3>
        <div>
          <Column {...config} />
        </div>
      </div>
      <div className="mt-4">
        <h3 className="mb-5 title">Dernières commandes</h3>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { Table, Button, message } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrderById, treatOrder } from "../features/auth/authSlice";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Produit",
    dataIndex: "name",
  },
  {
    title: "Marque",
    dataIndex: "brand",
  },
  {
    title: "Quantité",
    dataIndex: "count",
  },
  {
    title: "Prix",
    dataIndex: "amount",
  },
];

const ViewOrder = () => {
  const location = useLocation();
  const orderId = location.pathname.split("/")[3];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    dispatch(getOrderById(orderId))
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching order data:", error);
        setLoading(false);
      });
  }, [orderId, dispatch]);

  const orderState = useSelector((state) => state.auth.orderbyid);

  const handleTreatOrder = async () => {
    setProcessing(true);
    try {
      await dispatch(treatOrder(orderId));
      message.success("Order successfully treated!");
      // Refetch the order data to refresh the UI
      await dispatch(getOrderById(orderId));
    } catch (error) {
      message.error("Error treating the order.");
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (orderState && orderState.products && orderState.products.length > 0) {
    const data1 = orderState.products.map((product, index) => ({
      key: index + 1,
      name: product.product.title,
      brand: product.product.brand,
      count: product.count,
      amount: product.product.price,
    }));

    const totalPrice = orderState.paymentIntent.amount;
    const shippingCost = orderState.paymentIntent.shippingCost || 0;
    const grandTotal = totalPrice + shippingCost;

    return (
      <div className="container mt-4">
        <h3 className="mb-4 title text-center">Voir la commande</h3>
        <div>
          <Table columns={columns} dataSource={data1} pagination={false} />
          {orderState && orderState.paymentIntent && (
            <div className="order-info">
              <h4>Informations de la commande :</h4>
              <p><strong>Nom :</strong> {orderState.paymentIntent.name}</p>
              <p><strong>Numéro de téléphone :</strong> {orderState.paymentIntent.phoneNumber}</p>
              <p><strong>Adresse :</strong> {orderState.paymentIntent.address}</p>
              <p><strong>Zone :</strong> {orderState.paymentIntent.zone}</p>
              {orderState.paymentIntent.comment && (
                <div className="comment-section">
                  <h4 className="comment-title">Commentaire de l'utilisateur :</h4>
                  <p>{orderState.paymentIntent.comment}</p>
                </div>
              )}
            </div>
          )}
          <div className="total-price text-end">
            <p>Prix total : {totalPrice} FCFA</p>
            <p>Coût d'expédition : {shippingCost} FCFA</p>
            <p>Montant total : {grandTotal} FCFA</p>
          </div>
          <div className="text-end mt-3">
            <Button
              type="primary"
              onClick={handleTreatOrder}
              loading={processing}
              disabled={orderState.isTreated} // Disable button if already treated
            >
              {orderState.isTreated ? "Déjà traité" : "Marquer comme traité"}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="text-center mt-5">No data available for this order.</div>;
  }
};

export default ViewOrder;

import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
    createDeliveryZone,
    getDeliveryZone,
  resetState,
  updateDeliveryZone,
} from "../features/deliveryzone/deliveryZoneSlice";


let schema = yup.object().shape({
  zone: yup.string().required("Le nom de la zone de livraison est requis"),
  price : yup.string().required("Le prix de la zone de livraison est requis"),
});

const AddDeliveryZone = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const getDeliveryZoneId = location.pathname.split("/")[3];
  const newDeliveryZone = useSelector((state) => state.deliveryZone);

  const {
    isSuccess,
    isError,
    isLoading,
    createdDeliveryZone,
    deliveryZoneName,
    deliveryZonePrice,
    updatedDeliveryZone,
  } = newDeliveryZone;

  useEffect(() => {
    if (getDeliveryZoneId !== undefined) {
      dispatch(getDeliveryZone(getDeliveryZoneId));
    } else {
      dispatch(resetState());
    }
  }, [getDeliveryZoneId]);

  useEffect(() => {
    if (isSuccess && createdDeliveryZone) {
      toast.success("Zone de livraison ajoutée avec succès !");
    }
    if (isSuccess && updatedDeliveryZone) {
      toast.success("Zone de livraison mise à jour avec succès !");
      navigate("/admin/deliveryZone-list");
    }
    if (isError && deliveryZoneName) {
      toast.error("Quelque chose s'est mal passé !");
    }
  }, [isSuccess, isError, isLoading]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      zone: deliveryZoneName || "",
        price: deliveryZonePrice || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (getDeliveryZoneId !== undefined) {
        const data = { id: getDeliveryZoneId, deliveryZoneData: values };
        dispatch(updateDeliveryZone(data));
        dispatch(resetState());
      } else {
        dispatch(createDeliveryZone(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState);
        }, 300);
      }
    },
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {getDeliveryZoneId !== undefined ? "Modifier" : "Ajouter"} Zone de livraison
      </h3>
      <div>
        <form action="" onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            name="name"
            onChng={formik.handleChange("zone")}
            onBlr={formik.handleBlur("zone")}
            val={formik.values.zone}
            label="Entrer le nom de la zone de livraison"
            id="name"
          />
          <div className="error">
            {formik.touched.zone && formik.errors.zone}
          </div>
            <CustomInput
                type="text"
                name="price"
                onChng={formik.handleChange("price")}
                onBlr={formik.handleBlur("price")}
                val={formik.values.price}
                label="Entrer le prix de la zone de livraison"
                id="price"
            />
          <button
            className="btn btn-success border-0 rounded-3 my-5"
            type="submit"
          >
            {getDeliveryZoneId !== undefined ? "Modifier" : "ajouter"} Zone de livraison
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddDeliveryZone;
import { React, useEffect, useState } from "react";
import CustomInput from "../components/CustomInput";
import ReactQuill from "react-quill";
import { useNavigate, useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { Select } from "antd";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import { createProducts, resetState, getAProduct, updateProduct } from "../features/product/productSlice";

let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  brand: yup.string().required("Brand is Required"),
  category: yup.string().required("Category is Required"),
  subcategory: yup.string().required("Subcategory is Required"),
  quantity: yup.number().required("Quantity is Required"),
  ingredients: yup.string().required("Ingredients is Required"),
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const location = useLocation();
  const getProductId = location.pathname.split("/")[3];

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    if (getProductId !== undefined) {
      dispatch(getAProduct(getProductId));
    } else {
      dispatch(resetState());
    }
  }, [dispatch, getProductId]);

  const brandState = useSelector((state) => state.brand.brands);
  const catState = useSelector((state) => state.pCategory.pCategories);
  const imgState = useSelector((state) => state.upload.images);
  const newProduct = useSelector((state) => state.product);
  const { isSuccess, isError, isLoading, createdProduct, products } = newProduct;
  
  const productToEdit = products.find(product => product._id === getProductId);

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Produit ajouté avec succès!");
      navigate("/admin/product-list");
    }
    if (isError) {
      toast.error("Quelque chose s'est mal passé!");
    }
  }, [isSuccess, isError, isLoading, createdProduct, navigate]);

  useEffect(() => {
    if (productToEdit) {
      setImages(productToEdit.images);
    }
  }, [productToEdit]);

  const handleImageUpload = (acceptedFiles) => {
    dispatch(uploadImg(acceptedFiles));
  };

  useEffect(() => {
    if (imgState.length > 0) {
      setImages(imgState.map(i => ({ public_id: i.public_id, url: i.url })));
    }
  }, [imgState]);

  const formik = useFormik({
    initialValues: {
      title: productToEdit?.title || "",
      description: productToEdit?.description || "",
      price: productToEdit?.price || "",
      brand: productToEdit?.brand || "",
      category: productToEdit?.category || "",
      subcategory: productToEdit?.subcategory || "",
      quantity: productToEdit?.quantity || "",
      ingredients: productToEdit?.ingredients || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const productData = {
        ...values,
        images,
      };
      if (getProductId) {
        dispatch(updateProduct({ _id: getProductId, ...productData }));
      } else {
        dispatch(createProducts(productData));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 3000);
      }
    },
    enableReinitialize: true,
  });

  return (
    <div>
      <h3 className="mb-4 title">
        {getProductId ? "Modifier" : "Ajouter"} un produit
      </h3>
      <div>
        <form onSubmit={formik.handleSubmit} className="d-flex gap-3 flex-column">
          <CustomInput
            type="text"
            label="Entrer le titre du produit"
            name="title"
            onChng={formik.handleChange("title")}
            onBlr={formik.handleBlur("title")}
            val={formik.values.title}
          />
          <div className="error">
            {formik.touched.title && formik.errors.title}
          </div>
          <div className="">
            <p>description du produit</p>
            <ReactQuill
              theme="snow"
              name="description"
              onChange={value => formik.setFieldValue("description", value)}
              value={formik.values.description}
            />
          </div>
          <div className="error">
            {formik.touched.description && formik.errors.description}
          </div>
          <CustomInput
            type="text"
            label="Entrer les ingrédients du produit"
            name="ingredients"
            onChng={formik.handleChange("ingredients")}
            onBlr={formik.handleBlur("ingredients")}
            val={formik.values.ingredients}
          />
          <div className="error">
            {formik.touched.ingredients && formik.errors.ingredients}
          </div>
          <CustomInput
            type="number"
            label="Entrer le prix du produit"
            name="price"
            onChng={formik.handleChange("price")}
            onBlr={formik.handleBlur("price")}
            val={formik.values.price}
          />
          <div className="error">
            {formik.touched.price && formik.errors.price}
          </div>
          <select
            name="brand"
            onChange={formik.handleChange("brand")}
            onBlur={formik.handleBlur("brand")}
            value={formik.values.brand}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Selectionnez une marque</option>
            {brandState.map((i, j) => (
              <option key={j} value={i.title}>
                {i.title}
              </option>
            ))}
          </select>
          <div className="error">
            {formik.touched.brand && formik.errors.brand}
          </div>
          <select
            name="category"
            onChange={formik.handleChange("category")}
            onBlur={formik.handleBlur("category")}
            value={formik.values.category}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="">Selectionnez une sous-catégorie</option>
            {catState.map((i, j) => (
              <option key={j} value={i.title}>
                {i.title}
              </option>
            ))}
          </select>
          <div className="error">
            {formik.touched.category && formik.errors.category}
          </div>
          <select
            name="subcategory"
            onChange={formik.handleChange("subcategory")}
            onBlur={formik.handleBlur("subcategory")}
            value={formik.values.subcategory}
            className="form-control py-3 mb-3"
            id=""
          >
            <option value="" disabled>
              Selectionnez une catégorie
            </option>
            <option value="k-beauty">K-beauty</option>
            <option value="j-beauty">J-Beauty</option>
            <option value="parapharmacy">Parapharmacy</option>
          </select>
          <div className="error">
            {formik.touched.subcategory && formik.errors.subcategory}
          </div>
          <CustomInput
            type="number"
            label="Entrer la quantité du produit en stock"
            name="quantity"
            onChng={formik.handleChange("quantity")}
            onBlr={formik.handleBlur("quantity")}
            val={formik.values.quantity}
          />
          <div className="error">
            {formik.touched.quantity && formik.errors.quantity}
          </div>
          <div className="border-1 p-5 text-center drag-and-drop">
            <Dropzone onDrop={handleImageUpload}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>
                      Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="showimages d-flex flex-wrap gap-3">
            {images.map((i, j) => (
              <div className="position-relative" key={j}>
                <button
                  type="button"
                  onClick={() => dispatch(delImg(i.public_id))}
                  className="btn-close position-absolute"
                  style={{ top: "10px", right: "10px" }}
                ></button>
                <img src={i.url} alt="" width={200} height={200} />
              </div>
            ))}
          </div>
          <button className="btn btn-success border-0 rounded-3 my-5" type="submit">
            {getProductId ? "Modifier" : "Ajouter"} un produit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Addproduct;

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteDeliveryZone, getDeliveryZones } from "../features/deliveryzone/deliveryZoneSlice";
import CustomModal from "../components/CustomModal";



const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Zone",
    dataIndex: "zone",
    sorter: (a, b) => a.zone.length - b.zone.length,
  },
  {title: "Prix", dataIndex: "price", 
  dataIndex: "price",
  sorter: (a, b) => a.price - b.price},
  {
    title: "Action",
    dataIndex: "action",
  },
];

const DeliveryZoneList = () => {
  const [open, setOpen] = useState(false);
  const [deliveryZoneId, setDeliveryZoneId] = useState("");
  const showModal = (e) => {
    setOpen(true);
    setDeliveryZoneId(e);
  };

  const hideModal = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDeliveryZones());
  }, []);
  const deliveryZoneState = useSelector((state) => state.deliveryZone.deliveryZones);
  const data1 = [];
  for (let i = 0; i < deliveryZoneState.length; i++) {
    data1.push({
      key: i + 1,
      zone: deliveryZoneState[i].zone,
        price: deliveryZoneState[i].price,
      action: (
        <>
          <Link
            to={`/admin/deliveryZone/${deliveryZoneState[i]._id}`}
            className=" fs-3 text-danger"
          >
            <BiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(deliveryZoneState[i]._id)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    });
  }
  const deleteDeliveryZone = (e) => {
    dispatch(deleteDeliveryZone(e));

    setOpen(false);
    setTimeout(() => {
      dispatch(getDeliveryZones());
    }, 100);
  };
  return (
    <div>
      <h3 className="mb-4 title">Zones de livraison</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => {
          deleteDeliveryZone(deliveryZoneId);
        }}
        title="Etes-vous sûr de vouloir supprimer cette zone de livraison?"
      />
    </div>
  );
};

export default DeliveryZoneList;
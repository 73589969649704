import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const login = async (user) => {
  const response = await axios.post(`${base_url}user/admin-login`, user, { withCredentials: true });
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const getOrders = async () => {
  const response = await axios.get(`${base_url}user/getallorders`, config);

  return response.data;
};

const treatOrder = async (id) => {
  console.log("treatOrder", id);
  const response = await axios.post(`${base_url}user/treat-order/${id}`, null, config);

  return response.data;
};

const getOrder = async (id) => {
  const response = await axios.post(
    `${base_url}user/getorderbyuser/${id}`,
    "",
    config
  );

  return response.data;
};

const getOrderById = async (id) => {
  const response = await axios.get(`${base_url}user/order/${id}`, config);

  return response.data;
};

const logout = async () => {
  console.log("logout");
  const response = await axios.get(`${base_url}user/logout`, config);
  console.log("logout", response.data);
  localStorage.removeItem("user");
  return response.data;

}

const monthlyRevenue = async () => {
  const response = await axios.get(`${base_url}user/get-orders-mstate`, config);
  return response.data;
};

const yearlyRevenue = async () => {
  const response = await axios.get(`${base_url}user/get-orders-ystate`, config);
  return response.data;
}

const authService = {
  login,
  getOrders,
  getOrder,
  logout,
  getOrderById,
  monthlyRevenue,
  yearlyRevenue,
  treatOrder

};

export default authService;

import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url } from "../../utils/baseUrl";
const getDeliveryZones = async () => {
  const response = await axios.get(`${base_url}deliveryzone/`, config);

  return response.data;
};

const createDeliveryZone = async (deliveryZone) => {
    const response = await axios.post(`${base_url}deliveryzone/`, deliveryZone, config);
    
    return response.data;
    }

const updateDeliveryZone = async (deliveryZone) => {
    const response = await axios.put(
        `${base_url}deliveryzone/${deliveryZone.id}`,
        {
        name: deliveryZone.deliveryZoneData.name,
        price: deliveryZone.deliveryZoneData.price,
        deliveryTime: deliveryZone.deliveryZoneData.deliveryTime,
        },
        config
    );
    
    return response.data;
    };

const getDeliveryZone = async (id) => {
    const response = await axios.get(`${base_url}deliveryzone/${id}`, config);
    
    return response.data;
    }

const deleteDeliveryZone = async (id) => {
    const response = await axios.delete(`${base_url}deliveryzone/${id}`, config);
    
    return response.data;
    }
const deliveryZoneService = {
    getDeliveryZones,
    createDeliveryZone,
    deleteDeliveryZone,
    getDeliveryZone,
    updateDeliveryZone,
    };

export default deliveryZoneService;
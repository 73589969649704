import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authServices";

const getUserfromLocalStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  user: getUserfromLocalStorage,
  orders: [],
  orderbyid: [],
  yearlyRevenue: [],
  monthlyRevenue: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const response =  await authService.login(userData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrders = createAsyncThunk(
  "order/get-orders",
  async (thunkAPI) => {
    try {
      return await authService.getOrders();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const treatOrder = createAsyncThunk(
  "order/treat-order",
  async (id, thunkAPI) => {
    try {
      return await authService.treatOrder(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrderByUser = createAsyncThunk(
  "order/get-order",
  async (id, thunkAPI) => {
    try {
      return await authService.getOrder(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrderById = createAsyncThunk(
  "order/get-order-by-id",
  async (id, thunkAPI) => {
    console.log("id", id);
    try {
      return await authService.getOrderById(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (thunkAPI) => {
    try {
      console.log("logout action");
      const response = await authService.logout();
      console.log("logout action response", response);
      return response.data;
    } catch (error) {
      console.log("logout action error", error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const monthlyRevenue = createAsyncThunk(
  "order/monthly-revenue",
  async (thunkAPI) => {
    try {
      return await authService.monthlyRevenue();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const yearlyRevenue = createAsyncThunk(
  "order/yearly-revenue",
  async (thunkAPI) => {
    try {
      return await authService.yearlyRevenue();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);




export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (buildeer) => {
    buildeer
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.message = "success";
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
        state.message = "success";
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(getOrderByUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderByUser.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.orderbyuser = action.payload;
        state.message = "success";
      })
      .addCase(getOrderByUser.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
        state.isLoading = false;
      })
      .addCase(logout.pending, (state) => {
        // Gestion du chargement pendant la déconnexion
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        // Réinitialisation de l'état après déconnexion réussie
        state.isLoading = false;
        state.user = null; // L'utilisateur est déconnecté
        state.isSuccess = false;
        state.isError = false;
        state.message = "Logged out successfully";
      })
      .addCase(logout.rejected, (state, action) => {
        // Gestion des erreurs de déconnexion
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.error.message || "Logout failed";
      })
      .addCase(getOrderById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.loading = false;
        state.orderbyid = action.payload;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(monthlyRevenue.pending, (state) => {
        state.isLoading = true;
      }
      )
      .addCase(monthlyRevenue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.monthlyRevenue = action.payload;
      }
      )
      .addCase(monthlyRevenue.rejected, (state) => {
        state.isLoading = false;
      }
      )
      .addCase(yearlyRevenue.pending, (state) => {
        state.isLoading = true;
      }
      )
      .addCase(yearlyRevenue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.yearlyRevenue = action.payload;
      }
      )
      .addCase(yearlyRevenue.rejected, (state) => {
        state.isLoading = false;
      }
      )
      ;
  },
});

export default authSlice.reducer;

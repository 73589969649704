import React, { useEffect } from "react";
import { Table, Tag } from "antd"; // Import Tag for styling
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrders } from "../features/auth/authSlice";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Nom",
    dataIndex: "name",
  },
  {
    title: "Produit",
    dataIndex: "product",
  },
  {
    title: "Montant",
    dataIndex: "amount",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Statut",
    dataIndex: "status",
  }
];

const Orders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  const orderState = useSelector((state) => state.auth.orders);
  console.log("orderState", orderState);

  // Sort orders by date in descending order
  const sortedOrders = [...orderState].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const data1 = sortedOrders.map(order => ({
    key: order._id,
    name: order.orderby?.firstname || 'Unknown',
    product: (
      <Link to={`/admin/order/${order._id}`}>
        Voir la commande
      </Link>
    ),
    amount: order.paymentIntent?.amount || 0,
    date: new Date(order.createdAt).toLocaleString(),
    status: order.isTreated ? (
      <Tag color="green">Traité</Tag>
    ) : (
      <Tag color="red">Non Traité</Tag>
    ),
  }));

  return (
    <div>
      <h3 className="mb-4 title">Commandes</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  );
};

export default Orders;

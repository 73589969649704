import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import deliveryZoneService from "./deliveryZoneService";
import { toast } from "react-toastify";

export const getDeliveryZones = createAsyncThunk(
    "deliveryzone/get-deliveryzones",
    async (thunkAPI) => {
        try {
        return await deliveryZoneService.getDeliveryZones();
        } catch (error) {
        return thunkAPI.rejectWithValue(error);
        }
    }
    );
export const createDeliveryZone = createAsyncThunk(
    "deliveryzone/create-deliveryzone",
    async (deliveryZone, thunkAPI) => {
        try {
        return await deliveryZoneService.createDeliveryZone(deliveryZone);
        } catch (error) {
        return thunkAPI.rejectWithValue(error);
        }
    }
    );
export const deleteDeliveryZone = createAsyncThunk(
    "deliveryzone/delete-deliveryzone",
    async (id, thunkAPI) => {
        try {
        return await deliveryZoneService.deleteDeliveryZone(id);
        } catch (error) {
        return thunkAPI.rejectWithValue(error);
        }
    }
    );
export const getDeliveryZone = createAsyncThunk(
    "deliveryzone/get-deliveryzone",
    async (id, thunkAPI) => {
        try {
        return await deliveryZoneService.getDeliveryZone(id);
        } catch (error) {
        return thunkAPI.rejectWithValue(error);
        }
    }
    );
export const updateDeliveryZone = createAsyncThunk(
    "deliveryzone/update-deliveryzone",
    async (deliveryZone, thunkAPI) => {
        try {
        return await deliveryZoneService.updateDeliveryZone(deliveryZone);
        } catch (error) {
        return thunkAPI.rejectWithValue(error);
        }
    }
    );
export const resetState = createAction("Reset_all");

const initialState = {
    deliveryZones: [],
    isError: false,
    isLoading: false,
    isSuccess: false,
    };
const deliveryZoneSlice = createSlice({

    name: "deliveryzone",
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getDeliveryZones.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getDeliveryZones.fulfilled, (state, action) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.deliveryZones = action.payload;
        })
        .addCase(getDeliveryZones.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
        })
        .addCase(createDeliveryZone.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createDeliveryZone.fulfilled, (state, action) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.deliveryZones.push(action.payload);
            toast.success("Zone de livraison ajoutée avec succès !");
        })
        .addCase(createDeliveryZone.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
            toast.error("Quelque chose s'est mal passé !");
        })
        .addCase(deleteDeliveryZone.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteDeliveryZone.fulfilled, (state, action) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.deliveryZones = state.deliveryZones.filter(
            (deliveryZone) => deliveryZone.id !== action.payload.id
            );
            toast.success("Zone de livraison supprimée avec succès !");
        })
        .addCase(deleteDeliveryZone.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
        })
        .addCase(getDeliveryZone.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(getDeliveryZone.fulfilled, (state, action) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.deliveryZones = action.payload;
        })
        .addCase(getDeliveryZone.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
        })
        .addCase(updateDeliveryZone.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateDeliveryZone.fulfilled, (state, action) => {
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.deliveryZones = state.deliveryZones.map((
            deliveryZone
            ) =>
            deliveryZone.id === action.payload.id ? action.payload : deliveryZone
            );
            toast.success("Zone de livraison mise à jour avec succès !");
        }
        )
        .addCase(updateDeliveryZone.rejected, (state, action) => {
            state.isError = true;
            state.isLoading = false;
            toast.error("Quelque chose s'est mal passé !");
        });
    }
    });
export default deliveryZoneSlice.reducer;


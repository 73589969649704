import React from "react";
import CustomInput from "../components/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setPromo } from "../features/product/productSlice";



const AddPromo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId } = useParams();
  const [promoPrice, setPromoPrice] = React.useState(0);



  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("productId", productId);
    console.log("promoPrice", promoPrice); 
    dispatch(setPromo({productId, promoPrice}));
    toast.success("Promotion ajoutée avec succès");
    navigate("/admin/list-product");
  };

const handleChange = (e) => {
    setPromoPrice(e.target.value);
};

return (
    <form onSubmit={handleSubmit}>
        <h3 className="mb-4 title">Promotion</h3>
        <input
            name="promoPrice" 
            type="number"
            placeholder="Entrer le montant de la promotion"
            value={promoPrice}
            onChange={handleChange}
        />
        
        <button type="submit">Ajouter la promotion</button>
    </form>
);
}

export default AddPromo;
